
<template>
	<div>
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				 <el-form-item label="年级" label-width="50px" prop="code">
				    <el-select v-model="queryForm.nianji" placeholder="请选择年级">
				       <el-option v-for="(item,index) in nainjis"  :value="item.name" :label="item.name" :key="index"/>
				    </el-select>
				 </el-form-item>
<!-- 				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" />
				</el-form-item> -->
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="getData">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<right-toolbar :show-search.sync="showSearch" @queryTable="getData" />
			</el-row>
			<div class="w-100 flex-all font-lger font-bold" style="height: 60px;">
				{{data.wj_name || ''}}
			</div>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ (queryForm.page - 1) * queryForm.limit + scope.$index + 1 }}
				  </template>
				</el-table-column>
				<el-table-column label="年级" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.nianji }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="班级" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.banji }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="已填人数" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.cy_num }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="未填人数" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.wt_num }}</span>
				  </template>
				</el-table-column>
			 </el-table>
		</el-card>
	</div>
</template>

<script>
	import common from "@/common/mixins/common.js"
	import Breadcrumb from '@/components/Breadcrumb';
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			Breadcrumb,
		},
		data() {
			return {
				preUrl: '',
				autoRequest: false,
				id:'',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
					nainji:'',
				},
				nainjis:[],
				data:{},
				list:[],
				list_length:0,
				emptyShow:true,
			}
		},
		mounted() {
			if(this.$route.query.id){
				this.id = this.$route.query.id
			}
			this.getData()
			this.getStudentNianji()
		},
		computed:{
			
		},
		methods:{
			getStudentNianji(){
				this.axios.get('/manage/student/nianji',{
					params:{}
				}).then(res=>{
					if(res.status){
						this.nainjis = res.data
					}
				})
			},
			getData(){
				this.axios.get('/manage/wj/view2',{
					params:{
						id:this.id,
						nianji:this.queryForm.nianji
					}
				}).then(res=>{
					if(res.status){
						this.data = res.data
						this.list = this.data.list
					}
				})
			},
			arraySpanMethod({ row, column, rowIndex, columnIndex }) {
				if(rowIndex === this.list_length){
					if(columnIndex === 0){
						return [2, 2];
					}
					if(columnIndex === 1){
						return [1, 2];
					}
				}
				if(rowIndex === this.list_length+1){
					if(columnIndex === 0){
						return [1, 2];
					}
				}
				if(rowIndex === this.list_length+2){
					if(columnIndex === 0){
						return [1, 2];
					}
					if(columnIndex === 2){
						return [1, 2];
					}
					if(columnIndex === 3){
						return [1, 2];
					}
				}
				if(rowIndex === this.list_length+3){
					if(columnIndex === 0){
						return [1, 2];
					}
					if(columnIndex === 2){
						return [1, 2];
					}
					if(columnIndex === 3){
						return [1, 2];
					}
				}
				if(rowIndex === this.list_length+4){
					if(columnIndex === 0){
						return [1, 7];
					}
				}
			},
		},
		watch:{
			data:{
				handler (val) {
					if(val.length == 0){
						this.emptyShow = true
					}else{
						this.emptyShow = false
					}
				},
			}
		},
	}
</script>

<style>
</style>